<script setup lang="ts">

import PhoneInput from "~/components/UI/Inputs/PhoneInput.vue";

const formDataDefault = {
	phone: '',
	name: ''
}

const {fetchForCallData} = useTelegram();

const btnText = ref('Отправить заявку')

let formDataRequest = reactive(formDataDefault);
function completeRequest() {
	btnText.value = 'Заявка отправлена'
	setTimeout(() => {
		btnText.value = 'Отправить заявку'
		formDataRequest = formDataDefault;
	}, 2000)
}

async function sendRequest() {
	const {phone, name} = formDataRequest;
	try {
		await fetchForCallData(phone, name,  'for-call')
	} catch (e) {
		console.log(e)
	}
	
	completeRequest()
}

</script>

<template>
	<div class="wrapper">
		<div class="form block">
			<v-form @submit.prevent="sendRequest" class="form__body">
				<h3 class="form__title">
					Нужна помощь с <br>
					выбором?
				</h3>
				<p class="form__info">
					Наш менеджер перезвонит вам в течение 20 минут и расскажет обо всех доступных вариантах покупки и выгодных предложениях.
				</p>
				<v-text-field label="Как вас зовут?" v-model="formDataRequest.name"/>
				<PhoneInput v-model="formDataRequest.phone"/>
				<v-btn type="submit" block>{{btnText}}</v-btn>
			</v-form>
			<v-img src="/interier.webp" class="form__img" cover/>
		</div>
	</div>
	
</template>

<style scoped lang="scss">

	.form {
		
		@media screen and (max-width: 860px) {
			grid-template-columns: 1fr;
			margin-top: 32px !important;
			gap: 16px;
			&__img {
				border-radius: 0 0 12px 12px !important;
				height: 200px;
			}
			
			&__body {
				margin: 16px !important;
			}
		}
		
		
		display: grid;
		grid-template-columns: 1fr 1.5fr;
		gap: 32px;
		border-radius: 12px;
		background: #FFFFFF;
		
		&__body {
			margin: 32px;
		}
		
		&__title {
			font-size: 32px;
			margin-bottom: 16px;
		}
		&__info {
			margin-bottom: 32px;
		}
		
		&__img {
			border-radius: 0 12px 12px 0;
		}
	}


</style>